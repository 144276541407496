@import "@fontsource/tajawal"; 
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Tajawal", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pdf-header {
  position: relative;
}
.detail-section {
  position: absolute;
  z-index: 999;
  color: white;
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  width: 38%;
  align-items: center;
  margin-right: 15px;
}

.date-section {
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.boder {
  margin-top: 15px;
  height: 80px;
  border-right: 3px solid white;
  padding-right: 30px;
  text-align: end;
}

.head-text {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  margin: 0;
  margin-bottom: 2px;
}
.head-value {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  margin: 0;
  background-color: white;
  border-radius: 5px;
  padding: 3px 7px;
  color: black;
}

.mt {
  margin-top: 13px;
}

.after-border {
  text-align: end;
}

.container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.inner-div {
  text-align: center;
  background-color: #04244a;
  padding: 10px;
  font-size: 18px;
  /* border-radius: 10px; */
  color: white;
}

.inventoryRowNew {
  width: 100%;
}

.grid-container {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}
.grid-container-risk {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
}

.custom-table {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 2px solid #e2e4e9;
  border-radius: 10px;
}

.custom-table-header {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  height: 50px;
  font-size: 20px;
}

.custom-table-cell {
  border-radius: 0 0 9px 9px;
  text-align: center;
  color: white;
}

.custom-table-blue-body {
  background-color: white;
  border-radius: 0 0 10px 10px;
}
.custom-table-blue-cell {
  background-color: #c2d6ff;
  color: #375dfb;
  height: 30px !important;
  font-size: 14px;
  font-weight: 400;

  display: flex;

  justify-content: center;
  align-items: center;
}

.custom-table-blue-header {
  color: #375dfb;
  font-size: 24px;
  font-weight: 700;
}

.custom-table-green-cell {
  background-color: #effaf6;
  color: #2d9f75;
  height: 30px !important;
  font-size: 14px;
  font-weight: 400;

  display: flex;

  justify-content: center;
  align-items: center;
}

.custom-table-green-header {
  color: #2d9f75;
}

.custom-table-red-cell {
  background-color: #fdedf0;
  color: #df1c41;
  height: 30px !important;
  font-size: 14px;
  font-weight: 400;

  display: flex;

  justify-content: center;
  align-items: center;
}

.custom-table-red-header {
  color: #df1c41;
}
.custom-table-orange-cell {
  background-color: #e2e4e9;

  color: #525866;
  height: 30px !important;
  font-size: 14px;
  font-weight: 400;

  display: flex;

  justify-content: center;
  align-items: center;
}

.custom-table-orange-header {
  color: #525866;
}

.parent {
  display: block;
}

.wrapper {
  width: 100%;
  display: block;
  float: left;
  /* break-inside: avoid; */
}

.break-inside {
  break-inside: avoid;
}

.Critical {
  color: white;
  background-color: rgb(227, 84, 84) !important;
}
.High {
  color: white;
  background-color: rgb(227, 119, 84) !important;
}
.MediumHigh {
  color: white;
  background-color: #f2994a !important;
}
.Medium {
  color: white;
  background-color: rgb(227, 170, 84) !important;
}
.Low {
  color: white;
  background-color: rgb(156, 209, 88) !important;
}

.score-contanier {
  border: 2px solid #e2e4e9;
  margin: 10px 0px;
  border-radius: 10px;
  overflow: hidden;
}

.widget-data {
  padding: 0px 20px 20px 20px;
}
