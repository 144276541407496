/* PHC Readiness & mot  Css */
.PHC-readiness-mobilisation {
    margin-top: 20px;
}

.main-head {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    /* background: #F6F8FA; */
   background: #696969 !important ;
   color: white !important;



}

.main-head-man-op {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    /* background-color: white !important; */
    background: white !important;
    color: black !important;

    

}

.mini-head {
    font-family: Inter;
    font-size: 8px;
    font-weight: 500;
    line-height: 9.68px;
    text-align: left;
    background: #B4B4B4 !important;


}


/* Week plan Css */
.week-plan {
    display: flex;
    justify-content: center;
    padding: 16px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
   background: #696969;
   /* background: #319DD6; */
   color: white !important;

}

.week-plan-qs {
    display: flex;
    padding: 16px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: left;
   /* background: #319DD6; */
   background: #B4B4B4;

    border-top: .2px solid #f0f0f0;
    border-right: none !important;
    border-left: none !important;
    border-bottom: none !important;
   color: white !important;




}

.week-plan-aw {
    display: flex;
    padding: 16px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: left;
    border: .2px solid #f0f0f0;
    border-bottom: none !important;
}

/* Patient Journey Enhancement Css */
.patient-journey-enhancement {
    margin-top: 20px;
}

.patient-journey-enhancement .mini-head {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: left;
    



}


.ant-table-tbody .mini-head {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    background-color: white !important;
}

.ant-table-tbody .main-head {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    background-color: white !important;
}


/* Training Css */
.training {
    margin-top: 20px;
}

.training .mini-head {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: left;



}
/* Branding Css */
.branding {
    margin-top: 20px;
}

.branding .mini-head {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: left;



}

/* Construction Asha Valley Css */
.construction-asha-valley {
    margin-top: 20px;
}

.construction-asha-valley .mini-head {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: left;



}
/* Screening Css */
.screening {
    margin-top: 20px;
}

.screening .mini-head {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: left;



}
/* program governance Css */
.programg-governance {
    margin-top: 20px;
}

.programg-governance .mini-head {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: left;



}
/* Risk and Migraton Css */
.risk {
    margin-top: 20px;
}

.risk .mini-head {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: left;



}
/* Aditional Remarks Css */
.aditional-remark {
    margin-top: 20px;
}

.aditional-remark .mini-head {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: left;



}
.ant-table-thead > tr > th {
   /* background: #319DD6 ;  */
   background: #696969;

   color: white ;

}
.value-style {
    color: black !important;
}




.sites-text {
    font-size: 14px !important;
}
 /* {
   background: #319DD6 !important ;

 } */