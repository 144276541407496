html {
  -webkit-print-color-adjust: exact;
}

.title-contianer {
  margin: 0px 10px !important;
  padding: 0px 20px !important;
  min-height: 40px;
}

.obligation-temp {
  border: 1px solid #cdd0d5 !important;
  border-radius: 5px 5px 0px 0px !important;
}

.standard-obl-pill {
  position: relative;
  display: inline-block;
  padding: 3px 20px !important; /* Adjust this value to create space for the circle */
  border-radius: 999px;
  background: #cbf5e5;
  color: #176448;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 !important;
}

.standard-obl-pill-dot {
  border-radius: 100%;
  margin-left: 4px;
  margin-right: 4px;
  width: 7px !important; /* Adjust this value to change the size of the circle */
  height: 7px !important; /* Adjust this value to change the size of the circle */
  background: #176448;
}

.value_container-obl {
  background-color: #fff !important;
  border-radius: 6px !important;
  border: 1px solid #e2e4e9 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 10px;
  margin: 0px;
}

.obli-question-body-title-container {
  border: 1px solid #cdd0d5;
  background-color: #f5f6f8 !important;
}

.obli-question-body-value {
  border-left: 1px solid #cdd0d5;
  border-right: 1px solid #cdd0d5;
  background-color: white !important;
  border-top: 1px solid #cdd0d5;
  margin-top: -1px;
}
.obli-question-body-value:last-child {
  border-bottom: 1px solid #cdd0d5;
}

.stats-container {
  border-radius: 5px;
  border: 1px solid #cdd0d5;
  margin-top: 10px;
  height: 400px;
  overflow: hidden;
}

.stats-title-container {
  background-color: #f6f8fa;
  border-bottom: 1px solid #cdd0d5;
  width: 100%;
  min-height: 40px;
  padding-top: 12px;
}

.stats-title {
  color: #0a0d14;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stats-body-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  border-bottom: 1px solid #cdd0d5;
}

.chart-container {
  display: flex;
  justify-content: center;
  /* height: 180px !important; */
}

.compliance-percent-contane-stats {
  width: 100% !important;
  margin: 5px 0px 20px 0px;
}

.stats-pils-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  /* overflow: auto; */
  /* gap: 10px; */
  margin-top: 10px;
}
