.compliance-percent {
  border-radius: 5px;
  border: 1px solid #e2e4e9;
  background: #f6f8fa;
  width: 100%;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
}

.compliance-percent-heading {
  color: #0A0D14;
  font-size: 24px;
  font-weight: 400;
  margin: 0;
  padding-top: 10px !important;
}

.compliance-percent-value {
  color: #000;
  font-size: 32px;
  font-weight: 400;
  margin: 0;
  border-right: 1px solid #e2e4e9;
  padding-right: 20px;
  padding-top: 10px !important;

}

.pdf-header-temp {
  background-color: #008556;
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;
}

.detail-section-obl {
  color: white;
  font-size: 20px;
  font-weight: 500;
  width: 38%;
}

.head-bottom-info {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 60px;
  align-items: center;
  border: #e2e4e9 1px solid;
  border-radius: 10px;
  margin-top: 5px;
}
.head-bottom-info-text {
  color: #008556;
  font-family: Tajawal;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 2px !important ;
}
.head-bottom-info-bold-text {
  color: #525866;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 2px !important ;
}

.mb-2{
  margin-bottom: 12px;
}