.PDF-body {
    padding: 20px;
}


.header {
    background: #319DD6;
    padding: 20px;
    text-align: center;
}

.logo img {
    width: 100px; /* Adjust as needed */
    height: auto; /* To maintain aspect ratio */
}
.week {
    border: .2px solid #f0f0f0;
}
.footer {
    padding: 0 130px;
    padding-bottom: 10px;
    
}
.hr {
    margin: 0 20px;
    border: .2px solid #f0f0f0;

}
.footer-text {
    padding: 20px 0;

    font-family: Roboto;
font-size: 13px;
font-weight: 400;
line-height: 13px;
text-align: left;
justify-content: center;
display: flex;
color: #CDD0D5;


}
.image-foot {
    display: flex;
    justify-content: end;
    margin: 10px;
}
/* .maindiv-footer {
    position: absolute;
} */
